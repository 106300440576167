.MyData {
  &__info {
    margin-bottom: 19px;

    button {
      text-decoration: underline;
      @include font(12, 400);
      color: $third;
      cursor: pointer;
    }
  }
}