.LayoutGrid {
  width: 100%;
  min-height: 100vh;
  background-color: $whiteDarkVariant;

  &__container {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    .Button{
      width: 100%;
      max-width: 450px;

      @include responsive($tablet) {
        max-width: 100%;
      }
    }
  }

  display: grid;
  grid-template-columns: 90px 1fr;
  grid-template-rows: 130px 1fr 70px;
  grid-template-areas: 
    "HeaderLayout BarInfoLayout" 
    "HeaderLayout MainLayout" 
    "HeaderLayout FooterLayout";

  @include responsive($tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: 135px 115px 1fr 60px;
    grid-template-areas: 
      "BarInfoLayout" 
      "HeaderLayout" 
      "MainLayout" 
      "FooterLayout";
  }

  &.active-grid {
    grid-template-rows: 135px auto 1fr 60px;
  }
}