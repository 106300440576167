.CalcelSignature {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;

    @include responsive($mobileBig) {
        justify-content: center;
    }

    button{
        display: flex;
        flex-direction: row;

        span:last-child{
            text-decoration: underline;
        }
    }

    button:hover {
        cursor: pointer;
    }

    .cancel-plan {
        margin-top: 16px;
        color: red;
        text-decoration: underline;
        font-size: 14px;
    }

    .modal-cancel-status {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 16px;

        p {
            font-size: 20px;
            width: 80%;
            
            span {
                color: $quaternary;
                font-size: 20px;
                font-weight: 700;

                &.canceled {
                    color: red;
                }

                &.inProgress {
                    color: orange;
                }
            }
        }

        .buttons-holder {
            display: flex;
            flex-direction: row;
            gap: 16px;
            width: 100%;

            @include responsive($mobileBig) {
                flex-direction: column;
            }

            .Button--outlined {
                border: 1px solid red;
                color: red;
            }
        }
    }

    .modal-cancel-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 16px;

        p {
            font-size: 20px;
            text-align: center;
        }

        .buttons-holder {
            display: flex;
            flex-direction: row;
            gap: 16px;
            width: 100%;

            @include responsive($mobileBig) {
                flex-direction: column;
            }

            .Button--outlined {
                border: 1px solid red;
                color: red;
            }
        }
    }
}
