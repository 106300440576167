.Modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  padding: 0 20px;
  opacity: 0;
  pointer-events: none;
  transition: all ease .5s;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  &.active-modal {
    opacity: 1;
    pointer-events: all;
  }

  &__content {
    max-width: 561px;
    width: 100%;
    height: auto;
    background-color: $white;
    border-radius: 20px;
    overflow: hidden;

    position: relative;

    &--title {
      padding: 20px 50px;
      background-color: $primary;
      text-align: center;

      position: relative;

      h3 {
        @include font(20, 600, 22, $white);
      }

      .Close {
        transform: translateY(-40%);

        position: absolute;
        top: 50%;
        right: 15px;

        span {
          font-size: 32px;
          color: $white;
          cursor: pointer;
        }
      }
    }

    &--body {
      overflow-y: auto;
      max-height: 70vh;
      padding: 20px;
    }
  }
}