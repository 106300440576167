.Details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;

  @include responsive($tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include responsive($mobileBig) {
    grid-template-columns: repeat(1, 1fr);
  }

  &__item {
    .InfoCard__container {
      height: 100%;
    }
  }
}