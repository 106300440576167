.ButtonLink,
.Button,
.ButtonExternal {
  width: 100%;
  height: fit-content;
  border-radius: 999px;
  padding: 17px 0;
  background-color: $secondary;
  @include font(16, 400, 19, $white);
  border: 0;
  outline: 0;
  transition: all ease .5s;
  cursor: pointer;

  display: flex;
  justify-content: center;

  &:hover {
    background-color: $primary;
  }

  &--outlined {
    background-color: transparent;
    border: 1px solid $secondary;
    color: $secondary;

    &:hover {
      background-color: transparent;
      border-color: $primary;
      color: $primary;
    }
  }
}