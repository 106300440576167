.InfoCard {
  width: 100%;
  min-height: 160px;

  &__container {
    padding: 20px;
    border-radius: 5px;
    background-color: $white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &--title {
      margin-bottom: 7px;

      h3 {
        @include font(20, 400);
        color: $grayDark;
        font-family: 'Paytone One';
      }
    }

    &--lines {
      .TextField {
        span {
          &:first-child {
            @include font(16, 500);
            color: $primary;
          }

          &:last-child {
            @include font(14, 400);
          }
        }
      }
    }

    &--link {
      a {
        @include font(16, 400);
        color: $third;
      }
    }
  }
}