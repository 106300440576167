.ModalContent {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
  opacity: 0;
  pointer-events: none;
  transition:  all ease .5s;

  display: flex;
  justify-content: flex-end;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  &.active-modal {
    opacity: 1;
    pointer-events: all;

    .ModalContent__area {
      margin-right: 0;
    }
  }

  &__area {
    max-width: 768px;
    width: 100%;
    height: 100%;
    background-color: $white;
    box-shadow: -4px 0 20px rgba(0, 0, 0, .25);
    overflow-y: auto;
    margin-right: -768px;
    transition: all ease .5s;

    &--container {
      width: 100%;
      height: 100%;
      padding: 40px;

      @include responsive($mobileBig) {
        padding: 40px 20px;
      }

      .Close{
        width: 100%;
        display: flex;
        justify-content: end;
        margin-bottom: 8px;

        button{
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover{
            cursor: pointer;
          }
        }
      }

      .Options {
        padding-bottom: 10px;
        border-bottom: 1px solid $gray;
        margin-bottom: 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @include responsive($tablet) {
          align-items: center;
          flex-direction: column;
          gap: 16px;
        }

        &__title {  
          margin-right: 20px;

          @include responsive($mobileBig) {
            margin-right: 0;
            margin-bottom: 10px
          }

          h3 {
            @include font(32, 600, 36, $grayDark);
          }
        }

        &__buttons {
          display: flex;
          flex-direction: row;
          gap: 16px;
          align-items: center;

          @include responsive($mobileBig) {
            flex-direction: column;
            width: 100%;
          }

          & .FileLabel {
            @include responsive($mobileBig) {
              max-width: 100%;
            }

            span{
              white-space: nowrap;
            }
          }
          
          &--import {
            span {
              padding: 16px 20px;
              background-color: $grayLight;
              border: 1px solid $gray;
              @include font(14, 400, 16);
              cursor: pointer;
              white-space: nowrap;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            input {
              display: none;
            }
          }
        }
      }

      .ImagesList {
        width: 100%;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        @include responsive($mobileBig) {
          grid-template-columns: repeat(2, 1fr);
        }

        .Image {
          border: 1px solid transparent;
          padding: 10px;
          cursor: pointer;

          &:hover {
            border-color: $gray;
          }

          img {
            width: 100%;
            height: 17vw;
            max-height: 137px;
            margin-bottom: 5px;

            object-fit: cover;
            object-position: center;

            @include responsive($mobileBig) {
              height: 28vw;
            }
          }

          p {
            max-width: 103px;
            width: 13vw;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @include font(12, 400, 16);
            margin-bottom: 10px;

            @include responsive($mobileBig) {
              width: 21vw;
            }
          }

          &__buttons {
            display: flex;
            justify-content: flex-end;

            button {
              font-size: 16px;
              color: $red;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}