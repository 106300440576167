* {
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, a, span {
  margin: 0;
  font-size: 14px;
}

a {
  color: $black;
  text-decoration: none;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
}