.FormSettingsPJ {
  &__inputs {
    &--duo {
      margin-bottom: 25px;

      display: flex;

      @include responsive($mobileBig) {
        flex-direction: column;
      }

      label {
        @include responsive($mobileBig) {
          margin-bottom: 25px;
        }

        &:first-child {
          padding-right: 25px;

          @include responsive($mobileBig) {
            padding-right: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__button {
    display:flex;
    justify-content:center;
  }
}