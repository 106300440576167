@mixin responsive($target) {
  @media #{$target} {
    @content;
  }
}

@mixin font($fontSize, $fontWeight, $lineHeight: $fontSize + 2, $color: #000) {
  font-size: #{$fontSize}px;
  font-weight: $fontWeight;
  line-height: #{$lineHeight}px;
  color: $color;
}