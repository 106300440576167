.Header {
  grid-area: HeaderLayout;
  background-color: $primary;
  transition: all ease .5s;

  display: flex;
  flex-direction: column;

  position: relative;

  @include responsive($tablet) {
    background-color: transparent;
    overflow: hidden;
  }

  &__top {
    border-bottom: 1px solid $white;

    @include responsive($tablet) {
      display: none;
    }

    &--container {
      padding: 20px;
      padding-bottom: calc(20px - 4px);

      a {
        display: block;
        height: fit-content;
      }
    }
  }

  &__bottom {
    flex: 1;

    &--container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include responsive($tablet) {
        padding: 20px 16px;
      }

      .Navigation {
        &__menu {
          margin: 0;
          padding: 0;
          list-style: none;

          @include responsive($tablet) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        }
      }

      .More {
        display: none;

        @include responsive($tablet) {
          display: block;
          transform: translateX(-50%);

          position: absolute;
          bottom: 0;
          left: 50%;

          &.active-arrow {
            button {
              transform: rotate(180deg);
            }
          }
        }

        button {
          font-size: 36px;
          background-color: transparent;
          outline: 0;
          border: 0;
          padding: 0;
        }
      }

      .Version {
        padding: 8px 10px;
        margin-top: 120px;
        text-align: center;

        @include responsive($tablet) {
          display: none;
        }

        img {
          margin-bottom: 8px;
        }

        p {
          @include font(14, 400);
          color: $white;
        }
      }
    }
  }
}