.AccountView {
  &__header {
    width: 100%;
    height: 90px;
    background-color: $primary;

    &--container {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      padding: 19px;
    }
  }

  &__content {
    min-height: 500px;
    background-color: $whiteDark;

    display: flex;
    flex-direction: column;

    &--container {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      padding: 32px 16px;
      
      flex: 1;
      display: flex;
    }
  }

  &__footer {
    min-height: 500px;
    background-color: $primary;
    width: 100%;
    display: flex;
    flex-direction: column;

    &--top {
      flex: 1;
      display: flex;
      flex-direction: column;

      .ContainerFooterTop {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        padding: 20px;
        padding-top: 110px;
        padding-bottom: 99px;

        flex: 1;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        
        @include responsive($tablet) {
          grid-template-columns: 1fr;
        }

        &__collumn {
          padding: 5px;

          @include responsive($tablet) {
            margin: 0 auto;
            margin-bottom: 16px;
            text-align: center;

            &:last-child {
              margin-bottom: 0;
            }
          }

          @include responsive($mobileBig) {
            margin: 0;
            margin-bottom: 16px;
            text-align: left;
            border-bottom: 1px solid $white;

            &:first-child {
              border: 0;
            }

            &:last-child {
              border: 0;
            }
          }

          img {
            max-width: 204px;
            width: 100%;
          }

          h3 {
            @include font(18, 500, 21, $white);
            margin-bottom: 16px;

            @include responsive($tablet) {
              font-weight: 600;
            }
          }

          &--links,
          &--address
          &--social {
            display: flex;
            flex-direction: column;
          }

          &--links {
            @include responsive($tablet) {
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            @include responsive($mobileBig) {
              align-items: flex-start;
            }

            a {
              width: fit-content;
              @include font(14, 400);
              color: $white;
              margin-bottom: 16px;
            }
          }

          &--address {
            p {
              @include font(14, 400);
              color: $white;
              margin-bottom: 16px;
            }
          }

          &--social {
            flex-direction: row;

            a {
              font-size: 20px;
              color: $white;
              margin-right: 8.5px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    &--bottom {
      height: 70px;
      background-color: $whiteDarkVariant;

      .ContainerBottomTop {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        padding: 20px;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
          @include font(14, 400);
        }
      }
    }
  }
}