.FormUserUser {
  &__inputs {
    margin-bottom: 26px;

    &--duo {
      display: flex;

      @include responsive($tablet) {
        flex-direction: column;
      }

      .InputDuo {
        margin-bottom: 20px;

        &:first-child {
          padding-right: 46px;

          @include responsive($tablet) {
            padding-right: 0;
          }
        }
      }
    }
  }

  .multiple {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    label {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      display: flex;
      align-items: center;
      gap: 5px;

      button {
        max-width: 80px;
        padding: 5px;
        margin: 0;

        &.active {
          background-color: #19376E;
          position: relative;

          svg {
            position: absolute;
            top: -5px;
            right: -5px;
            color: green;
          }
        }
      }
    }

    select {
      padding: 15px;
      border: 1px solid #CCC;
      border-radius: 5px;
    }
  }

  &__content {
    margin: 20px 0;
  }

  &__button {
    padding: 0;
    width: 100%;
    display:flex;
    justify-content:center;

    &.grid-button {
      grid-template-columns: 1fr 1fr;
      gap: 42px;

      @include responsive($tablet) {
        grid-template-columns: 1fr;
        gap: 14px;
      }
    }

    @include responsive($tablet) {
      padding-right: 0;
    }
  }
}