.plans-main-area {
  position: relative;
  width: 100%;
}

.Slider-Dots {
  position: absolute;
  bottom: -24px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;

  & li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    &.slick-active button:before {
      opacity: 1;
      color: $secondary;
    }

    & button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:before {
        font-family: "slick";
        font-size: 12px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: "•";
        text-align: center;
        opacity: 0.5;
        color: $third;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}

.periods-modal {
  position: relative;
}

.plans-card-holder {
  width: 100%;
  display: flex !important;
  justify-content: center;
  padding: 19px;
}

.plans-modal{
  .Modal__content--body{
    padding-bottom: 40px;
  }
}
