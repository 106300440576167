.DropdownArea {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &__top {
    height: 66px;
    cursor: pointer;

    &--container {
      height: 100%;
      padding: 0 32px;
      
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Title {
        h3 {
          @include font(20, 400);
          color: $grayDark;
          font-family: 'Paytone One';
        }
      }

      .Arrow {
        &.active-arrow {
          button {
            transform: rotate(180deg);
          }
        }

        button {
          font-size: 11px;
          color: $grayDark;
          transition: all ease .5s;
        }
      }
    }
  }

  &__bottom {
    display: none;
    transition: all ease .5s;

    &.active-bottom {
      display: block;
    }

    &--container {
      padding: 32px;
    }
  }
}