.TextAreaLabel {
  width: 100%;
  height: 200px;

  position: relative;

  textarea {
    width: 100%;
    height: 100%;
    resize: none;
    border-radius: 20px;
    border: 1px solid $gray;
    outline: 0;
    padding: 20px;
    @include font(12, 400);
  }

  span {
    @include font(14, 400);
    color: $blackLight;
    background-color: $white;
    padding: 0 10px;
    border-radius: 999px;
    transition: all ease .5s;
    margin-top: -7px;
    cursor: text;

    position: absolute;
    left: 20px;
  }
}