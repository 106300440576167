.FormMailTags {
  &__inputs {
    margin-bottom: 20px;
  }

  &__buttons {
    margin-bottom: 40px;

    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    button {
      max-width: fit-content !important;
      min-width: 150px;
      padding: 17px 20px;
      margin: 0 !important;

      @include responsive($mobileBig) {
        min-width: 100%;

        &.Button--outlined {
          flex: 1;
          white-space: nowrap;
          max-width: unset  !important;
          min-width: calc(50% - 20px);
        }
      }
    }
  }
}