.EditorWorld {
  display: flex;
  flex-direction: column;

  position: relative;

  &__buttons {
    align-self: flex-end;

    &.active-editor {
      button {
        top: 10px;
      }
    }

    button {
      font-size: 24px;
      color: $grayDark;
      cursor: pointer;

      position: absolute;
      top: 95px;
      right: 10px;
      z-index: 2;
    }
  }

  .ck-editor__editable_inline {
    padding-right: 45px;
  }

  &__code {
    border: 1px solid $gray;
    border-radius: 10px;

    pre{
      word-break: break-word !important;
    }

    textarea {
      outline: 0 !important;
    }
  }
}