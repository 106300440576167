.TextField {
  @include font(12, 500);
  color: $primary;
  margin-right: 4px;

  display: flex;
  align-items: center;

  span {
    &:last-child {
      @include font(12, 400);
      color: $primary;
      margin-left: 4px;
    }
  }

  input {
    width: 75px;
    margin-left: 4px;
    padding: 0;
    border: 0;
    background-color: transparent;
    outline: 0;
    @include font(12, 400);
    color: $primary;
  }

  button {
    font-size: 12px;
    color: $primary;
    background-color: transparent;
    outline: 0;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
}