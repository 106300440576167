.TableArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:20px;

  @include responsive($tablet) {
    max-width: unset;
  }

  &__table {
    width: 100%;
    background-color: $grayLight;

    @include responsive($tablet) {
      display: none;
    }

    &.cursor-click {
      .TableArea__table--bottom {
        tr {
          cursor: pointer;
        }
      }
    }

    &--top {
      tr {
        background-color: $whiteDarkVariant;

        th {
          @include font(14, 500);
          color: $primary;
          padding: 5px 0;
          text-align: left;
          padding-right: 6px;
          min-width: 50px;

          svg {
            float: right;
          }

          &:hover{
            cursor: pointer;
            opacity: 0.7;
          }
        }
      }
    }

    &--bottom {
      tr {
        &:nth-child(2n) {
          background-color: $whiteDarkVariant;
        }

        td {
          @include font(12, 400);
          color: $blackLight;
          padding: 8px 10px;

          &:not(:first-child){
            word-break: break-word;
          }

          &:last-child{
            min-width: 100px;

            @include responsive($desktopSmall) {
              min-width: 80px;

              & button:first-child{
                margin-bottom: 8px;
              }
            }
          }

          button {
            width: fit-content;
            @include font(14, 400);
            text-decoration: underline;
            margin-right: 10px;
            cursor: pointer;

            &.edit,
            &.view {
              color: $third;
            }

            &.del {
              color: red;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &__custom {
    display: none;

    @include responsive($tablet) {
      display: block;
    }

    &--item {
      padding: 13px 15px 13px 19px;
      border-radius: 5px;
      border: 1px solid $gray;
      margin-bottom: 11px;

      display: flex;
      flex-direction: column;

      .TextField {
        span {
          &:last-child {
            color: $black;
            overflow-wrap: anywhere;
          }
        }
      }

      button {
        width: fit-content;
        @include font(14, 400);
        text-decoration: underline;
        cursor: pointer;

        &.edit,
        &.view {
          color: $third;
        }

        &.del {
          color: red;
        }
      }
    }
  }

  &__pagination-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include responsive($tablet) {
      flex-direction: column-reverse;
      gap:16px;
    }
  }

  &__pagination {
    display: flex;

    &--button {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-size: 16px;
      margin-right: 20px;
      border: 1px solid $gray;
      transition: all ease .5s;
      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }

      @include responsive($tablet) {  
        &:nth-child(5) {
          display: none;
        }
      }

      @include responsive($mobileSmall) {  
        &:nth-child(4) {
          display: none;
        }
      }

      &:hover {
        background-color: $primary;
        color: $white;
        border-color: $primary;

        p {
          color: $white
        }
      }

      &.active {
        background-color: $primary;
        color: $white;
        border-color: $primary;

        p {
          color: $white
        }
      }

      p {
        @include font(12, 400);
        transition: all ease .5s;
      }
    }
  }

  &__pagination-qty{
    select{
      border-radius: 20px;
      padding:2px 4px;
    }
  }

}
