.MailTags {
  .CheckboxAll {
    margin-bottom: 20px;
  }

  .TdContact {
    display: flex;
    gap: 5px;
    justify-content: space-between;

    button {
      color: $primary;
    }
  }
}