.Box {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: #EEE;

  @include responsive($tabletSmall) {
    padding:  0;
  }

  &__content {
    max-width: 556px;
    width: 100%;
    margin: 0 auto;
    background-color: $white;
    border-radius: 20px;
    overflow: hidden;

    @include responsive($tablet) {
      max-width: 100%;
    }

    &--details {
      padding: 20px;

      @include responsive($tabletSmall) {
        padding: 20px 0;
      }

      .Title {
        text-align: center;
        margin-bottom: 10px;
  
        h3 {
          @include font(24, 600, 26, $black);
        }
      }
  
      .Body {
        text-align: center;
        margin-bottom: 20px;
  
        p {
          @include font(16, 400, 18, $blackLight)
        }
      }
  
      .Info {
        p {
          justify-content: center;
          margin-bottom: 10px;
  
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .Respost {
        .data {
          margin-top: 10px;
          background-color: $white;
          padding: 10px;
        }
      }
    }

    &--button {
      .Button {
        border-radius: 0 0 20px 20px;

        @include responsive($tabletSmall) {
          border-radius: 50px;
        }
      }
    }
  }
}