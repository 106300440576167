.FormAccountForgot {
  &__inputs {
    margin-bottom: 27px;

    &--control {
      margin-bottom: 37px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}