.InputLabel {
  width: 100%;
  height: 52px;

  display: flex;
  align-items: center;

  position: relative;
  z-index: 0;

  span {
    @include font(14, 400);
    color: $blackLight;
    background-color: $white;
    padding: 0 10px;
    border-radius: 999px;
    margin-top: -53px;
    transition: all ease .5s;
    cursor: text;

    position: absolute;
    left: 20px;

    &:hover {
      margin-top: -53px;
    }
  }

  input {
    width: 100%;
    height: 100%;
    outline: 0;
    border-radius: 999px;
    border: 1px solid $gray;
    padding: 0 30px;
    @include font(12, 400);
  }
}

.ColorLabel {
  width: fit-content;
  margin-bottom: 20px;

  display: block;

  p {
    @include font(12, 400);
    margin-bottom: 10px;
  }
}

.CheckboxLabel {
  width: fit-content;
  cursor: pointer;

  display: flex;
  align-items: center;

  input {
    display: none;
  }

  input:checked + span {
    background-color: $primary;
  }

  span {
    width: 20px;
    height: 20px;
    border: 1px solid $gray;
    margin-right: 16px;
    transition: all ease .5s;
  }

  p {
    @include font(16, 400, 19);
  }
}

.FileLabel {
  max-width: 358px;
  width: 100%;
  height: 52px;
  border-radius: 999px;
  border: 1px solid $gray;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  &.active-file {
    span {
      margin-top: -53px
    }
  }

  span {
    @include font(14, 400);
    color: $blackLight;
    padding: 0 30px;
    border-radius: 999px;
    transition: all ease .5s;
  }

  p {
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 30px;
  }

  img {
    width: 52px;
    height: 52px;
    padding: 2px;

    border: 2px solid $gray;
    border-radius: 50%;
    
    object-fit: cover;
    object-position: center;
  }
  
  input {
    display: none;
  }
}